import React, { PureComponent, Suspense, lazy } from "react";
import queryString from "query-string";
//import image from "../../assets/images/vodafone_play_logo_90.png";
import search from "../../assets/images/search.png";
import search_imt from "../../assets/images/search_imt.png";
import profile_icon_default from "../../assets/images/profile_icon_default.png";
import profile_icon_imt from "../../assets/images/profile_icon_imt.png";
// import watchlist_default from "../../assets/images/watchlist_default.png";
import watchlist_default from "../../assets/images/nav_drawer_my_Watchlist_icon_apex_new.png";
import logout_default from "../../assets/images/logout_default_icon.png";
import account_edit_icon from "../../assets/images/account_edit_icon.png";
import brand_icon from "../../assets/images/brand_icon.png";
// import account_password_icon from "../../assets/images/account_password_icon.png";

import actionbar_logo_light_theme from "../../assets/images/actionbar_logo_light_theme.png";
import actionbar_search_icon_light_theme from "../../assets/images/actionbar_search_icon_light_theme.png";

// import logout_default from "../../assets/images/logout_default.png";
import mobile_search from "../../assets/images/search_apex.png";
import search_close from "../../assets/images/search_close.svg";
// import apexlogo from "../../assets/images/apexlogo.png"
// import apexlogo108 from "../../assets/images/apexlogo108.png"
//import apexheaderlogo from "../../assets/images/actionbar_brand_logo_hdpi.png"
import manoramalogo from "../../assets/images/manorama_max_logo_dark_bg.png";
// import search_close_imt from "../../assets/images/search_close_imt.svg";
// import Add_to_home_icon from "../../assets/images/Add_to_home_icon.png";
import actionbar_back_icon from "../../assets/images/actionbar_back_icon.png";
import top_close_icon from "../../assets/images/top_close_icon.png";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import gplayimg from "../../assets/images/googleplay.png";
// import appleimg from "../../assets/images/applestore.png";
// import chromeimg from "../../assets/images/chromeimg.png";
// import firefoximg from "../../assets/images/firefoximg.png";

import lensEvents from "../../LensAnalytics";
// import firebaseEvents from "../../common/firebaseEvents"
import DesktopTopMenu from "./DesktopTopMenu";
import { queryStringParse, isPremimumUser, isDemographicPlan } from "../../utils/portalhelper";
import Search from "./Search";
//import Menu from "./Menu";
import "./Header.css";
import myplexAPI from "../../api/myplexAPI";
// import Routes from "../../Routes/routes";
import SideMenu from "./SideMenu";
import indexedDB from "../../utils/indexedDB";
// import Signin from "./Signin";
// import Appmodal from "./Appmodal";
// import { Fingerprint } from "../../utils/fingerprint";
import PublishingHouseIds from "../../utils/publishingHouseIds";
// import cleverTap from "../../common/clevertap";
import {
	ToastsContainer,
	ToastsStore,
	ToastsContainerPosition,
  } from "react-toasts";
import {
  isAndroid,
  // isMobile,
  // isChrome,
  // isFirefox,
  // isSafari,
  isIOS,
} from "react-device-detect";

import Topheader from "../../general/Header/Topheader";
import { URL_MENU } from "../../api/myplexAPI";
// // import { Modal } from "react-bootstrap";
// import tips_screen_idea from "../../assets/images/tips_screen_idea.png";
// import tips_screen_vfplay from "../../assets/images/tips_screen_vfplay.png";
// import indexedDB from "../../utils/indexedDB";
// import config from "../../utils/config";
// import serviceWorker from "../../utils/serviceWorker";
import axios from "axios";
import Popupmodal from "./popupmodal";
import DemoGraphicCongratsPopup from "./DemoGraphicCongratsPopup";
// import Popupmodal from "./popupmodal";

// import googlePixel from "../../utils/googlePixel";
const Signin = lazy(() => import("./Signin"));
const Appmodal = lazy(() => import("./Appmodal"));
// var dayStyle = {
//   backgroundImage: `url(${toggle_switch_day_sprite})`
// };
// var nightStyle = {
//   backgroundImage: `url(${toggle_switch_day_sprite})`
// };

class header extends PureComponent {
  // state = {addClass: false}
  list = "";

  state = {
    caroselimages: [],
    pageName: "Home",
    logo: "",
    mainlogo: "",
    trending_search: false,
  };
  constructor(props, context) {
    super(props, context);
    this.handleShowAddmodal = this.handleShowAddmodal.bind(this);
    this.handleCloseAddmodal = this.handleCloseAddmodal.bind(this);    
    this.setpopup = this.setPopUpState.bind(this);
    this.setDemoGraphicCongratsPopup = this.setDemoGraphicCongratsPopupState.bind(this);
    this._isMounted = false;
    this.today_date = new Date().toLocaleDateString();
    this.popShow = this.popShow.bind(this);
    this.popClose = this.popClose.bind(this);
    this.closePopUp = this.setclosePopUpState.bind(this);
    this.state = {
      auth:
        localStorage.getItem("Logged-in") ||
        this.readCookie("logged-in") ||
        false,
      dropdown: false,
      showsearch: false,
      searchkeyword: "",
      searchmobile: true,
      flag: false,
      showcross: false,
      Desktoppopup: true,
      ShowAddmodal: true,
      ShowSubscribe:false,
      Showrenew:false,
      renewtext:"",
      // searchclose:false,
      // searchinput:false,

      menu: [],
      typingTimer: null,
      reel : false,
      popup:false,
      pwaDemographicConfig:"",
      pwaDemographicSuccessPopup:false,
      demographicDataSet:''
    };
    this.Signin = React.createRef();
  }
  handleShowAddmodal() {
    this.setState({ ShowAddmodal: true });
  }
  handleCloseAddmodal() {
    this.setState({ ShowAddmodal: false });
  }
  componentDidMount() {
    this.getproperties();
    let params = queryStringParse(this.props.location.search);
    // if (params.status !== undefined) {

    //     if (params.status === 'SUCCESS') {
    //       lensEvents.paymentStatus(this.props.match.params.Id,'SUCCESS');
    //     }else{
    //       lensEvents.paymentStatus(this.props.match.params.Id,'FAIL');
    //     }
    //   }
    this._isMounted = true;
    // console.log(this.props.menu)
    // Fingerprint();
    this.setState({"demographicDataSet":localStorage.getItem("demographicData")})
    this.checkLogin();
    this.getMenu();
    PublishingHouseIds();
    // this.zee5Promotion();
    const theme = localStorage.getItem("theme");
    if (theme != null) {
      theme === "dark-theme" ? this.setDark() : this.setLight();
    }
    this.delayTimer = null;
    // const settings = import(
    //   `../../environments/${process.env.REACT_APP_CONFIGARATION}`
    // );
    // if (window.location.pathname === "/sony-liv-banner") {

    //   document.getElementsByClassName("mobile_search")[0].style.display = "none";
    //   // document.getElementsByClassName("shake")[0].style.display = "none";
    //   document.getElementsByClassName("wrapper-logo")[0].style.display = "none";

    //   document.getElementsByClassName("installapp")[0].style.display = "none";

    //   document.getElementById("dropdown").style.display = "none";

    // }

    // settings.then(set => {
    //   this.setState({ logo: <img src={set.default.logo} alt="logo" /> });
    //   this.setState({
    //     mainlogo: (

    //       <img
    //         src={set.default.mainlogo}
    //         alt="mainlogo"
    //         className="main_idealogo"
    //       />
    //     )
    //   });
    // });

    /*Added to home screen*/
    window.addEventListener("appinstalled", (evt) => {
      var data = window.deviceDetect();
      // cleverTap.pwaInstalled(data);
      data["event"] = "App Install";
    });
    this.portalOpens();
    // serviceWorker();
    
  }
  getproperties = () => {
    if(sessionStorage.getItem('properties') === undefined || sessionStorage.getItem('properties') === null){
        myplexAPI.properties().then(response => {
            if(response.data.code === 200 && response.data.status === 'SUCCESS' && response.data.properties){
              sessionStorage.setItem('properties',JSON.stringify(response.data.properties));
            }   
            //this.demoGraphicPopup(response.data.properties);
        });
    }else{
      //this.demoGraphicPopup(JSON.parse(sessionStorage.getItem('properties')));
    }
     
  }
  demoGraphicPopup = (status) => {
    var properties = JSON.parse(sessionStorage.getItem('properties'));
    var isNotPremimumUser = isPremimumUser();
    var countryLocation = sessionStorage.getItem('user_country');
    var path = window.location.pathname;
    if(properties && properties.pwaDemographicConfig){
      var pwaDemographicConfig = JSON.parse(properties.pwaDemographicConfig);
      //console.log("pwaDemographicConfig ",pwaDemographicConfig);
      this.setState({pwaDemographicConfig : pwaDemographicConfig});
      if(pwaDemographicConfig.profileUpdate_popup_enabled && isNotPremimumUser && countryLocation && countryLocation === 'IN' && !path.includes('detail')){
        this.setState({popup:status});
      }
    }
  }
  setPopUpState = (state) => {
    this.setState({popup:state})
  }
  setDemoGraphicCongratsPopupState = (state) => {
    this.setState({pwaDemographicSuccessPopup:state})
  }
  handleClose = () => {
    this.setState({ Desktoppopup: false });
  };

  componentDidUpdate() {
    if (!this.props.location.pathname.includes("news")) {

      document.body.classList.add("dark-theme");
      document.body.classList.remove("white-theme");
    }

    if (
      document.getElementById("dropdown") != null &&
      document.getElementById("dropdown").classList.contains("openmenu")
    ) {
      document.getElementById("sidemenu").classList.remove("open");
      document.getElementById("dropdown").classList.remove("openmenu");
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  AddSearch = () => {
    //trading search results function
    document.getElementById("search_part").classList.add("open-search");
    document.getElementById("mainSearch").focus();
    this.setState({ showsearch: true });
    this.setState({ trending_search: true });
  };

  RemoveSearch = () => {
    document.getElementById("search_part").classList.remove("open-search");
    document.getElementById("mainSearch").value = "";
  };

  ClearSearch = () => {
    document.getElementById("mainSearch").value = "";
    this.setState({ showcross: false });
  };

  popClose() {
    this.setState({ show: false });
  }

  popShow() {
    this.setState({ show: true });
  
  }
  data1 = () =>{
    this.setState({popup : true});
  }
  setDark = () => {
    document.body.classList.add("dark-theme");
    document.body.classList.remove("light-theme");
    localStorage.setItem("theme", "dark-theme");
  };

  setLight = () => {
    document.body.classList.add("light-theme");
    document.body.classList.remove("dark-theme");
    localStorage.setItem("theme", "light-theme");
  };

  portalOpens = () => {
    // portal opens trigger once in a day
    const portal_opens_date = sessionStorage.getItem("portal_opens_date");
    if (
      portal_opens_date === undefined ||
      portal_opens_date === "" ||
      portal_opens_date === null
    ) {
      //this triggers when user opens first time
      // this.cleverTapPortalOpensEvent();
    } else {
      const previous_date = sessionStorage.getItem("portal_opens_date");
      if (this.today_date !== previous_date) {
        //this triggers when portal opens first time in a day
        // this.cleverTapPortalOpensEvent();
      }
    }
  };
  // cleverTapPortalOpensEvent = () => {
  //   const _self = this; // obtaining the class properties
  //   setTimeout(function () {
  //     const data = {};
  //     cleverTap.portalOpens(data);
  //     sessionStorage.setItem("portal_opens_date", _self.today_date);
  //     // console.log("portal_opens_date", _self.today_date);
  //   }, 1000);
  // };
  checkLogin = async () => {
    var that = this;
	  var profileData = '';
    const res = await myplexAPI.userLoginStatus();
    //console.log("res ",res);
    if(res){
    //myplexAPI.userLoginStatus().then(function (res) {
      //console.log("Login Check Res ",res);
      if(res.data.location){
        sessionStorage.setItem("user_country",res.data.location);
      }
      var countryLocation = sessionStorage.getItem('user_country');
      if (res.data.status === "ERR_NON_LOGGED_USER") {
        localStorage.removeItem("Logged-in");
        localStorage.setItem("selectedLanguageList", "");
        if(countryLocation && countryLocation === 'IN' && localStorage.getItem('userType') !== 'subscriber'){
          that.demoGraphicPopup(true);
        }
      } else if (res.data.status === "SUCCESS") {
        // console.log(res.data.result.profile.languages)
        var properties = JSON.parse(sessionStorage.getItem('properties'));
        if(properties && properties.pwaDemographicConfig){
          var pwaDemographicConfig = JSON.parse(properties.pwaDemographicConfig);
          that.setState({pwaDemographicConfig : pwaDemographicConfig});
        }
        var array = [];
        if (res.data.result.profile.languages !== null) {
          res.data.result.profile.languages.forEach((item) => array.push(item));
          localStorage.setItem("selectedLanguageList", array.join(","));
        }
        that.setState({ auth: true });
        window.auth = true;
        // console.log("uuid - ",localStorage.getItem("uuid"), "Type - ",typeof(localStorage.getItem("uuid")));
        // console.log("profileid - ",res.data.result.profile._id, "Type - ",typeof(res.data.result.profile._id));
        // console.log("localStorage - ",localStorage);
        if(localStorage.getItem("uuid")!=null && (+localStorage.getItem("uuid") !== res.data.result.profile._id)) {
          console.log("Both user ids not matched");
          let nonRemovedData = ['dob','gender','demoGraphicPopupShoweddata','demographicData','demographicSubmitClick','demoGraphicShowBanner','uuid'];
          let len = nonRemovedData.length;
           for(var i=0; i< len; i++){
              localStorage.removeItem(nonRemovedData[i]);

           }
        }
        //console.log("localStorage - ",localStorage);
        localStorage.setItem("circle_id", res.data.result.profile.circle_id);
        //localStorage.setItem("user_id", res.data.result.profile._id);
        localStorage.setItem("Logged-in", true);
        localStorage.setItem("mobileNum", res.data.result.profile.mobile_no);
        localStorage.setItem(
          "userMail",
          res.data.result.profile.emails[0].email
        );
        localStorage.setItem("userName", res.data.result.profile.first);
        localStorage.setItem(
          "masked_user_id",
          res.data.result.profile.masked_user_id
        );
        localStorage.setItem("gender", res.data.result.profile.gender);
        localStorage.setItem("dob", res.data.result.profile.dob);
        localStorage.setItem("uuid", res.data.result.profile._id);
		    profileData = res.data.result.profile;
        //console.log("profileData profile",profileData);
        // if(res.data.result.profile.dob === '' || res.data.result.profile.gender === ''){
        //   if(localStorage.getItem("demographicSubmitClick") === 'true'){
        //     var demographicData = localStorage.getItem("demographicData");
        //     //console.log("demographicData ",demographicData);
        //     if(demographicData !== undefined && demographicData !== null){
        //       //console.log("demographicData True");
        //       that.saveDemographicData(JSON.parse(demographicData));
        //     }
            
        //   }else{
        //     that.demoGraphicPopup(true);
        //   }          
        // }else{
        //   that.demoGraphicPopup(false);
        //   localStorage.removeItem("demographicData");
        //   localStorage.removeItem("demographicSubmitClick");
        //   localStorage.removeItem("demoGraphicPopupShoweddata");
        // }

        if (
          (
            res.data.result.profile.address === "" ||
            res.data.result.profile.city === "" ||
            res.data.result.profile.country === "" ||
            res.data.result.profile.state === "" ||
            res.data.result.profile.pincode === "" ||
            res.data.result.profile.pincode === "" ||
            res.data.result.profile.address === null ||
            res.data.result.profile.city === null ||
            res.data.result.profile.country === null ||
            res.data.result.profile.state === null ||
            res.data.result.profile.pincode === null ||
            res.data.result.profile.pincode === null)
        ) {
          // that.props.history.push("/login");
          localStorage.setItem("ProfileUpdate", true);
        } else {
          localStorage.setItem("ProfileUpdate", false);
        }
      }
    // });
    }
    //  const isObject =(value) =>{
    //   return (
    //     typeof value === 'object' &&
    //     value !== null &&
    //     !Array.isArray(value)
    //   );
    // }
    myplexAPI.getMyPackages().then(response => {
      // console.log("res", response)
      if(response.data.code === 200 && response.data.status === 'SUCCESS'){
        sessionStorage.setItem('userPackages', JSON.stringify(response.data));
        sessionStorage.setItem('userfeatures', JSON.stringify(response.data.features));
        var isDemographicPlanRes = isDemographicPlan(response.data.results,'DEMOGRAPHIC');
        var isNotPremimumUser = isPremimumUser();
        //console.log("profileData mypak",profileData);return false;
        var countryLocation = sessionStorage.getItem('user_country');
        localStorage.setItem('userType',response.data.userType);
        if(countryLocation && countryLocation === 'IN'){
          /*if (profileData && profileData !== '' && (profileData.dob === '' || profileData.gender === '') && isNotPremimumUser) {
              if (localStorage.getItem("demographicSubmitClick") === 'true') {
                  if (isDemographicPlanRes && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC') {
                      ToastsStore.info("You have already claimed the offer");
                      that.removeDemographicLocalstorageData();
                  } else if (!isNotPremimumUser) {
                      ToastsStore.info("You are not eligible for the offer");
                      that.removeDemographicLocalstorageData();
                  } else {
                      var demographicData = localStorage.getItem("demographicData");
                      //console.log("demographicData ",demographicData);
                      if (demographicData !== undefined && demographicData !== null) {
                          //console.log("demographicData True");
                          that.saveDemographicData(JSON.parse(demographicData));
                      }
                  }
              } else {
                  if (isDemographicPlanRes && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC' || !isNotPremimumUser) {
                      that.demoGraphicPopup(false);
                      that.removeDemographicLocalstorageData();
                  } else {
                      that.demoGraphicPopup(true);
                  }
              }
          } else {
              //console.log("profileData ",profileData);
              console.log("demographicSubmitClick", localStorage.getItem("demographicSubmitClick"));
              if (profileData && profileData !== '' && profileData.dob !== '' && profileData.gender !== '' && localStorage.getItem("demographicSubmitClick") !== undefined && localStorage.getItem("demographicSubmitClick") !== null && localStorage.getItem("demographicSubmitClick") === 'true') {
                  // console.log("You are not eligible for the offer");
                  // ToastsStore.info("You are not eligible for the offer");
                  console.log("isDemographicPlanRes ", isDemographicPlanRes);
                  if (isDemographicPlanRes && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC') {
                      ToastsStore.info("You have already claimed the offer");
                  } else if (!isNotPremimumUser) {
                      ToastsStore.info("You are not eligible for the offer");
                  } else {
                      ToastsStore.info("You are not eligible for the offer");
                  }
              } else if (profileData && profileData !== '' && (profileData.dob !== '' || profileData.gender !== '') && localStorage.getItem("demographicSubmitClick") !== undefined && localStorage.getItem("demographicSubmitClick") !== null && localStorage.getItem("demographicSubmitClick") === 'true') {
                  if (!isNotPremimumUser) {
                      ToastsStore.info("You are not eligible for the offer");
                  }
              }
              that.removeDemographicLocalstorageData();
              that.demoGraphicPopup(false);
          }*/
          if(localStorage.getItem("demographicSubmitClick") === 'true'){
            if(!isNotPremimumUser){
              ToastsStore.info("You are not eligible for the offer");              
              that.removeDemographicLocalstorageData();
            }else if(isDemographicPlanRes && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC'){
              ToastsStore.info("You have already claimed the offer");
              that.removeDemographicLocalstorageData();
            }else{
              var demographicData = localStorage.getItem("demographicData");
              //console.log("demographicData ",demographicData);
              if(demographicData !== undefined && demographicData !== null){
                //console.log("demographicData True");
                that.saveDemographicData(JSON.parse(demographicData));
              }
            }
          }else{
            if(isDemographicPlanRes && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC' || !isNotPremimumUser){
              that.demoGraphicPopup(false);
              that.removeDemographicLocalstorageData();
            }else{
              if(profileData && profileData !== '' && (profileData.dob === '' || profileData.gender === '')){
                that.demoGraphicPopup(true);
              }else{
                that.demoGraphicPopup(false);
                that.removeDemographicLocalstorageData();
              }              
            }
          }          
        }
      }      
      if (response && response.data && response.data.results && response.data.results.length > 0) {
        sessionStorage.setItem('userPacks', JSON.stringify(response.data.results));
        // var c = response.data.results.length - 1 ;
        // var EndDate =  response.data.results[c].validityEndDate.split(" ",1);
        // EndDate = new Date(EndDate);
        // EndDate = new Date("01/01/2023");
        let pack = sessionStorage.getItem("userPacks") &&  JSON.parse(sessionStorage.getItem("userPacks")).length > 0 && JSON.parse(sessionStorage.getItem("userPacks")).find(
          (requestobj) => requestobj.packType === "standard",
        );
        // console.log(isObject(pack));
        var EndDate ;
        if(response.data.results.length > 1){
          EndDate = new Date(Math.max.apply(null, response.data.results.map(function(e) {
            return new Date(e.validityEndDate);
          })));
        }else {
          EndDate = response.data.results[0].validityEndDate.split(" ",1);
          EndDate = new Date(EndDate);

        }
        var Today = new Date();
        var difference = this.dateDiffInDays(Today, EndDate);
        if(difference <= 30){
          //this.setState({ Showrenew: true });        
        }
        // if(response.data.results.length > 0 && !pack){
        // // console.log(this);
        //   this.setState({ ShowSubscribe: true });   
        // }
        if(response.data.features && response.data.features.enableSubscribeAction){
          
          this.setState({ ShowSubscribe: true });   

        }
        if(response.data.features && response.data.features.enableRenewAction){
          this.setState({ Showrenew: true , renewtext:response.data.features.renewText});  

        }
        // console.log(difference + ' days')
      } else {
        // this.demoGraphicPopup(JSON.parse(sessionStorage.getItem('properties')));
        this.setState({ ShowSubscribe: true });
        sessionStorage.setItem('userPacks', JSON.stringify(""));

      }
    });
  };
  removeDemographicLocalstorageData = () => {
    // localStorage.removeItem("demographicData");
    localStorage.removeItem("demographicSubmitClick");
    // localStorage.removeItem("demoGraphicPopupShoweddata");
    // localStorage.removeItem("demoGraphicShowBanner");
  };
  setclosePopUpState = (state) => {
    this.setState({pwaDemographicSuccessPopup:state})
  };
  saveDemographicData = (data) => {   
    var isNotPremimumUser = isPremimumUser();
    if(isNotPremimumUser){
      myplexAPI.demographicData(data).then(response => {
        //console.log("demographic response - ",response);
        if (response.data.code === 200 && response.data.status === "SUCCESS") {
          //console.log("demographic Response - ",true);
          this.setState({"pwaDemographicSuccessPopup" : true});
          // localStorage.removeItem("demographicData");
          // localStorage.removeItem("demographicSubmitClick");
          // localStorage.removeItem("demoGraphicPopupShoweddata");
          // localStorage.removeItem("demoGraphicShowBanner");
          this.removeDemographicLocalstorageData();
          
        }else{
          ToastsStore.info(response.data.message);
          this.removeDemographicLocalstorageData();
        }
      });
    }  
  }
   dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  getSsoClientId = () => {
    myplexAPI.getSsoAppInfo().then((response) => {
      // console.log(response)
      if (response.data.code === 200 && response.data.status === "SUCCESS") {
        localStorage.setItem("client_id", response.data.app_id);
        window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
      }
    });
  };
  handleShow = () => {
    if (this.state.auth) {
      if (this.state.dropdown) {
        document.removeEventListener("click", this.handledropdown, false);
      } else {
        document.addEventListener("click", this.handledropdown, false);
      }
      this.setState({ dropdown: !this.state.dropdown });
    } else {
      this.getSsoClientId();
    }
  };
  handledropdown = (e) => {
    try {
      if (this.node.contains(e.target)) {
        return;
      }
    } catch (err) {
      console.log(err);
    }
    document.removeEventListener("click", this.handledropdown, false);
    this.setState({ dropdown: false });
  };
  _Authenticate = () => {
    this.setState({ auth: true });
  };
  reload = () => {
    console.log("logout");
    window.location.reload();
  }
  _unSetAuth = () => {
    //this.setState({ auth: false, dropdown: false });
    lensEvents.logoutfn(localStorage.getItem("userIdLogin"));
    myplexAPI.logOut().then((response) => {
      //console.log(response, "logoutresponse");

      if (response.data.code === 200) {
        //temproary code start
        // lensEvents.logoutfn(localStorage.getItem("masked_user_id"));


        var client_id = localStorage.getItem("client_id");
        var id_token = localStorage.getItem("id_token");
        sessionStorage.clear();
        //localStorage.clear();
        let localStorageData = localStorage;
        if(localStorage.getItem('demographicData') !== undefined && localStorage.getItem('demographicData') !== null){
          localStorage.setItem('dob',JSON.parse(localStorage.getItem('demographicData')).dod+'-01-01');
          localStorage.setItem('gender',JSON.parse(localStorage.getItem('demographicData')).gender);
        }
        let nonRemovedData = ['dob','gender','demoGraphicPopupShoweddata','demographicData','demographicSubmitClick','demoGraphicShowBanner','userType','uuid']
        if(Object.keys(localStorageData).length > 0){
          Object.keys(localStorageData).forEach((key) => {
            if (!nonRemovedData.includes(key)) delete localStorageData[key];
          });
        }
        // document.cookie = "l_did=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.myplex.com";
        this.clearCookie("logged-in");
        
        if (id_token) {
          // window.location.href = `https://id.manoramaonline.com/end_session?post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_URL}&id_token_hint=${id_token}`;
          var url = `https://id.manoramaonline.com/end_session?post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_URL}&id_token_hint=${id_token}`;

          var ifrm = document.createElement("iframe");
          ifrm.setAttribute("src", url);
          ifrm.style.position = "absolute";
          ifrm.style.width = "0";
          ifrm.style.height = "0";
          ifrm.style.border = "0";
          document.body.appendChild(ifrm);
          ifrm.onload = window.location = "/";
        } else {
          // window.location.reload();
          window.location = "/"
        }



        //temporary end

        // window.location.reload();
        // myplexAPI
        //   .ssoLogout()
        //   .then((res) => {
        //     // console.log(res,'manorama logout response')
        //     sessionStorage.clear();
        //     localStorage.clear();
        //     this.clearCookie("logged-in");

        //     if (
        //       res &&
        //       res.data &&
        //       res.data.result &&
        //       res.data.result.length > 0 &&
        //       res.data.result.profile
        //     ) {
        //       lensEvents.logoutfn(res.data.result.profile);
        //     }
        //     window.location.reload();
        //   })
        //   .catch((err) => {
        //     console.log(err, "error logout");
        //     window.location.reload();
        //   });
      }
      var logoutdata = [];
      logoutdata["user id"] = localStorage.getItem("userIdLogin");
    });
  };

  _intializeSearch = async (e) => {
    e.persist(); //This synthetic event is reused for performance reasons.
    let that = this;
    var keyword = e.target.value;
    if (keyword === 0) {
      keyword = e.currentTarget.innerText;
    }
    // console.log(keyword)
    // console.log("timer" + this.delayTimer);

    clearTimeout(this.delayTimer);

    this.delayTimer = setTimeout(function () {
      if (e.keyCode === 13 && window.isMobile === undefined) {
        // that.setState({
        //   searchkeyword: keyword
        // })
        that._searchMore();
        return;
      }
      if (keyword.length > 0) {
        // firebase.analytics().logEvent('search', {'info':keyword});
        that.setState({
          showsearch: true,
          searchkeyword: keyword,
          trending_search: false,
          showcross: true,
        });
      } else {
        that.setState({
          showsearch: true,
          trending_search: true,
          showcross: false,
        });
      }
    }, 400);
    try {
      const player = document.getElementById("player");
      if (player !== null) {
        if (player.querySelector("video").paused === false) {
          player.querySelector("video").pause();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  _closeSearch = () => {
    this.setState({ showsearch: false });
  };
  getMenu = () => {
    const that = this;
    if (this.props.menu.length >= 0) {
      if (this._isMounted) {
        this.setState({
          menu: this.props.menu,
        });
      }
    } else {
      indexedDB(URL_MENU(window.nav_menu))
        .then(function (result) {
          if (result !== false) {
            that.setState({
              menu: result,
            });
          } else {
            that._fetchMenus();
          }
        })
        .catch(function (err) {
          that._fetchMenus();
        });
    }
  };
  _fetchMenus = () => {
    const nav_menu = window.nav_menu;
    this.list = localStorage.getItem("selectedLanguageList");
    this.appLanguage = localStorage.getItem("selectedAppLanguageList");

    myplexAPI
      .pullPageData(nav_menu, this.list, this.appLanguage)
      .then((response) => {
        this.navMenuResponse(response);
      });
  };
  navMenuResponse = (response) => {
    const that = this;
    if (that._isMounted) {
      that.setState({
        menu: response.data.results,
      });
      myplexAPI.storeIndexedDb(
        response.request.responseURL,
        response.data.results
      );
    }
  };
  _searchMore = () => {
    var keyword = this.state.searchkeyword;
    /* CleverTab Searched Event */
    const data = {
      category: "",
      keyword: keyword,
      "content clicked": "no",
      mode: "Direct",
    };
    localStorage.setItem("source", "search");
    localStorage.setItem("source details", keyword);
    localStorage.setItem("trackingId", "");

    // cleverTap.searched(data);
    this.setState({ showsearch: false });
    this.props.history.push(`/Searchresults?query=${keyword}`);
  };
  _installApp = () => {
    const data = {};
    // cleverTap.installAppButton(data);
    // googlePixel.pwaInstall();
    // window.fbq('track', 'Purchase', window.deviceDetect());  // facebook pixel custom event
  };
  addToHomeScreen = () => {
    const data = {};
    // cleverTap.addToHomeScreen(data);
  };
  readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  clearCookie(name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
  premiumRedirect = (actionUrl) => {
    // console.log(actionUrl);
    axios({
      method: "get",
      url: actionUrl + `&redirectUrl=${window.location}`,
    }).then((res) => {
      if ((res.data.success = "SUCCESS")) {
        if (res.data.ui) {
          // if(localStorage.getItem("ProfileUpdate")=="true"){
          //   localStorage.setItem(
          //     "redirectUrl",res.data.ui.redirect
          //   );
          //   this.props.history.push("/editprofile");

          // }else {

          window.location.href = res.data.ui.redirect;
          // }


        }
      }
    });
  };
  // desktopTopMenuData=()=>{
  //   return this.state.menu.map((m, index) => {
  //     let title = m.title;
  //           if (m.altTitle) {
  //               title = m.altTitle;
  //           }
  //           // console.log(title,m)
  //           const nav_link = slugify(m.title);
  //           if (m.layoutType === "menu") {
  //   return <React.Fragment key={index}>

  //      <li className="menu_list_item active">{title}</li>

  //     </React.Fragment>}
  //     else{
  //       return null
  //     }
  //   })
  // }
  // desktopTopMenu=React.memo(()=>{
  //   return  <div className="header-menu">
  //   <ul>
  //   {this.desktopTopMenuData}
  //   </ul>
  // </div>}
  // )
  render() {
    var search_bg = {
      backgroundImage: `url(${search})`,
    };

    var search_bg_imt = {
      backgroundImage: `url(${search_imt})`,
    };
    const reels = window.location.pathname === "/minis" || window.location.pathname.includes("/minis/") ? "Minis" : null;
    let itemData = this.state.menu.find(i => i.title === reels);
    itemData = itemData ? itemData : "";
     
    const shake = window.location.pathname === "/" ? "shake" : "shake";
    return (
      <div className="header.main-container">
        {/*  {process.env.REACT_APP_CONFIGARATION === "idea" ? (
          <div className="add_modal_section">
            <Modal show={this.state.ShowAddmodal} onHide={this.handleCloseShowAddmodal}>
            <Modal.Header className="add_modal_header" closeButton>
              
           <h5>Idea Movies and Tv</h5>
            </Modal.Header>
                    
                    <Modal.Body className="add_modal">
                      <img src={add_modal} />
                      <p>Enjoy 450+ LIVE TV channels, 10000+ latest Movies, TV Shows and Web series spread across 13+ languages on Vodafone Play available exclusively for Vodafone customers.</p>
                      <div class="select_add_modal">
                        <button type="button" class="add_modal_btn">Switch to Idea</button>
                      </div>
                    </Modal.Body>
                                     
            </Modal>
            </div>
            
             ) : (

            <div className="add_modal_section">
            <Modal show={this.state.ShowAddmodal} onHide={this.handleCloseShowAddmodal}>
            <Modal.Header className="add_modal_header" closeButton>
              
            <h5>Vodafone Play</h5>
            </Modal.Header>
                    
                    <Modal.Body className="add_modal">
                      <img src={add_modal} />
                      <p>Enjoy 450+ LIVE TV channels, 10000+ latest Movies, TV Shows and Web series spread across 13+ languages on Vodafone Play available exclusively for Vodafone customers.</p>
                      <div class="select_add_modal">
                        <button type="button" class="add_modal_btn">Switch to Vodafone</button>
                      </div>
                    </Modal.Body>
                                     
            </Modal>
            </div>
            )}*/}
        <header className={itemData !== undefined && itemData !== "" && itemData.title == reels ? "wrapper-header fixed-top reels_header" :"wrapper-header fixed-top"}>
          <Topheader />
          <SideMenu data={this.state.menu} flag={this.state.flag} p={this.props} />
          <nav className="navbar navbar-default">
            <div className="navbar-header">
              <div className="wrapper-logo">
                <div
                  className="collapse navbar-collapse hamburger_dropdown  mobile_menu"
                  id="bs-example-navbar-collapse-1"
                />

                <NavLink to="/">
                  <img
                    alt="logo"
                    src={manoramalogo}
                    className="mainlogo_mobile mainlogo_dark"
                  />
                  <img
                    alt="logo"
                    src={actionbar_logo_light_theme}
                    className="mainlogo_mobile mainlogo_white"
                  />
                  {/* <img alt="logo" src={apexott_logo57}  className="mainlogo"/> */}
                </NavLink>
              </div>
            </div>

            <DesktopTopMenu menuData={this.state.menu} />
          
            {localStorage.getItem("Logged-in") && this.state.ShowSubscribe==true ? (
                <div className="subscription">
                      <button onClick={() => {
                            this.premiumRedirect(
                              localStorage.getItem("premiumUrl")
                            );
                          }} type="button" className="subscription_btn">Subscribe </button>
                    </div>
             ) : localStorage.getItem("Logged-in") && this.state.Showrenew==true  && (
              <div className="subscription renew">
              <button onClick={() => {
                    this.premiumRedirect(
                      localStorage.getItem("premiumUrl")
                    );
                  }} type="button" className="subscription_btn">
                  {this.state.renewtext && <span>{this.state.renewtext}</span>} 
                   {/* <span className="monthtext">Get 1 month free</span> */}
              </button>
            </div>
              )} 
            <div className="navbar-collapse collapse_menu" id="">
              {/* <Menu /> */}
              <div className="right_content">         
                {isAndroid &&
                  !window.matchMedia("(display-mode: standalone)").matches &&
                  window.location.pathname !== "/sony-liv-banner" && (
                    <div
                      className="addto_home_btn"
                      onClick={this.addToHomeScreen}
                    >

                    </div>
                  )}

                <div className="profile_icon">
                  {process.env.REACT_APP_CONFIGARATION === "idea" ? (
                    <img
                      id="profile_img"
                      alt="profile"
                      src={profile_icon_imt}
                      // onClick={this.handleShow}
                    />
                  ) : (
                    <img
                      id="profile_img"
                      alt="profile"
                      src={profile_icon_default}
                   
                    />
                  )}
                 
                  {!localStorage.getItem("Logged-in") &&
                    <div className="profile_signin" onClick={this.handleShow}>
                      <ul>
                        <li>Sign In</li>
                      </ul>
                    </div>
                  }                
                 {localStorage.getItem("Logged-in") &&    <ul
                      className="dropdown-menu"
                      ref={(node) => {
                        this.node = node;
                      }}
                    >


                      {localStorage.getItem("Logged-in") && (
                        <NavLink to="/Myaccount">
                          <div className="profile_login_details">
                            <h4>{localStorage.getItem("userName")}</h4>
                            <h6>{localStorage.getItem("userMail")}</h6>
                            {/* <h6>+99845912913</h6> */}
                          </div>
                        </NavLink>
                      )}
                      {localStorage.getItem("Logged-in") && this.state.ShowSubscribe && (
                        <li
                          className="gopremium"
                          onClick={() => {
                            this.premiumRedirect(
                              localStorage.getItem("premiumUrl")
                            );
                          }}
                        >
                          <i className="mob-icon logout-icon">
                            <img
                              alt="gopremium_default"
                              className="gopremium_img"
                              src={brand_icon}
                            />
                          </i>
                          <span className="submenu-list__item">Go Premium</span>
                          <h5 className="">To Watch Premium Videos</h5>
                        </li>
                      )}

                      <li>
                        <NavLink to='/Editprofile'>
                          <i className="mob-icon logout-icon">
                            <img
                              alt="Edit_default"
                              src={account_edit_icon}
                            />
                          </i>
                          <span className="submenu-list__item">Edit Details</span></NavLink>
                      </li>

                      <li onClick={this._unSetAuth}>
                        <i className="mob-icon logout-icon">
                          <img alt="Signout_default" src={logout_default} />
                        </i>
                        <span className="submenu-list__item">Sign Out</span>
                      </li>
                    </ul>
               
                        }

                </div>
                <div className="mobile_search">
                  <div>
                    <div className="search_icon" onClick={this.AddSearch}>
                      <button className="search_hint_mobile change_icon">
                        <img
                          alt="mobile_search"
                          src={mobile_search}
                          className="mobile_search_dark"
                        />
                        <img
                          alt="mobile_search"
                          src={actionbar_search_icon_light_theme}
                          className="mobile_search_white"
                        />
                      </button>
                    </div>

                    <div className="close_icon">
                      <button className="search_hint_mobile">
                        <img alt="mobile_search" src={search_close} />
                      </button>
                    </div>

                    {this.state.searchinput && (
                      <div className="search-hint open-search">
                        <label className="label-hint">
                          <input
                            id="mainSearch"
                            name="query"
                            type="search"
                            placeholder="Search"
                            autoComplete="off"
                            maxLength="256"
                            onKeyUp={this._intializeSearch}
                          />{" "}
                          <button type="button" className="search-btn" />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="search-box">
                  <div className="nav navbar-nav navbar-right">
                    <div className="gloabl_serach_update">
                      <div className="search-holder open-search-hint">
                        <button className="search-hint-mobile" />
                      </div>
                      <div className="search-hint">
                        <div className="searchopen" id="search_part">
                          <label className="label-hint">
                            <img
                              alt="actionbar_back_icon"
                              className="actionbar_back"
                              src={actionbar_back_icon}
                              onClick={this.RemoveSearch}
                            />
                            {/* <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                              onClick={this.RemoveSearch}
                            /> */}
                            <input
                              id="mainSearch"
                              name="query"
                              type="search"
                              placeholder="Search"
                              autoComplete="off"
                              maxLength="256"
                              onKeyUp={(e) => this._intializeSearch(e)}
                            />
                            {this.state.showcross && (
                              <div
                                className="search_clear"
                                onClick={this.ClearSearch}
                              >
                                {process.env.REACT_APP_CONFIGARATION ===
                                  "idea" ? (
                                  <img
                                    alt="search_close_icon"
                                    src={search_close}
                                  />
                                ) : (
                                  <img
                                    alt="search_close_icon"
                                    src={search_close}
                                  />
                                )}
                              </div>
                            )}

                            {process.env.REACT_APP_CONFIGARATION === "idea" ? (
                              <button
                                type="button"
                                className="search-btn"
                                style={search_bg_imt}
                                onClick={this._searchMore}
                              />
                            ) : (
                              <button
                                type="button"
                                className="search-btn"
                                style={search_bg}
                                onClick={this._searchMore}
                              />
                            )}
                          </label>
                          {this.state.showsearch && (
                            <Search
                              keyword={this.state.searchkeyword}
                              searchFunction={this._searchMore}
                              closeSearch={this._closeSearch}
                              trendingSearch={this.state.trending_search}
                              _intializeSearch={this._intializeSearch}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </nav>
          <div>
            <Suspense fallback={<div></div>}>
              <Signin ref={this.Signin} setAuth={this._Authenticate} />
            </Suspense>
          </div>
        </header>
        <Suspense fallback={<div></div>}>
          <Appmodal show={this.state.show} popClose={this.popClose} />
        </Suspense>
		{/* && (localStorage.getItem("demographicData") === undefined || localStorage.getItem("demographicData") === null) */}
        {this.state.popup && (this.state.demographicDataSet === undefined || this.state.demographicDataSet === null) && <Popupmodal pwaDemographicConfig={this.state.pwaDemographicConfig}  showPopup = {this.state.popup} setpopup={this.setpopup} pageType="landing" />}
        {this.state.pwaDemographicSuccessPopup && <DemoGraphicCongratsPopup pwaDemographicSuccessPopup={this.state.pwaDemographicSuccessPopup} setDemoGraphicCongratsPopup={this.setDemoGraphicCongratsPopup} pwaDemographicConfig={this.state.pwaDemographicConfig} closePopUp={this.closePopUp}/>}
        {/* setpopup={this.setPopUpState()} */}
		<ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_CENTER}
        />
      </div>
    );
  }
}

export default withRouter(header);

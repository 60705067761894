import axios from "axios";
import { isMobile } from "react-device-detect";
import { deviceDetect } from "react-device-detect";
import db from "../utils/db";
import config from "../utils/config";
// import {encryption} from "../general/Header/Encryption"
import { encryption } from "../../commonfiles/encryption";

const PATH_API_CAROUSEL = "content/v2/carousel/";
const app = process.env.REACT_APP_CONFIGARATION;
//dev  Manorama
//let LINK = "https://devpaasapi.myplex.com/";

/**qa apex  */

//let LINK = "https://qamyplexapi.apexott.com/";

/**  QA  Manorama testing */


//let LINK = "https://qapaasapi.myplex.com/";

/** Manoram Live APi */

//let LINK = "https://apimyplex.manoramamax.com/";

// let LINK = "https://apimyplex.manoramamax.com/";

  let LINK = process.env.REACT_APP_APIURL ;

//const he_url = LINK.replace(/^https:\/\//i, "http://");

// dev tenant
// const tenantId = "1186b7f4-1391-4b59-bfd0-97e609de359f";

/**  QA Manorama tenant id */
//const tenantId = "f10c4847-86df-494c-a39f-0cc1df6feea7";


/**  Live Manorama tenant id */
// const tenantId = "a3e8a0ec-d407-4e23-8ddf-cafdfd0792fc";

 const tenantId = process.env.REACT_APP_TENANT;



//dev manorama
//const tenantId = "186000f3-0cdd-4e7a-8978-b29622ec3cfd";

const BASE_URL = () => LINK;

let META_URL;

META_URL = BASE_URL();

let serviceName = "VODAFONEPLAY";

const fingerprint = () => localStorage.getItem("fingerprint");
/* Publishing House Ids */
const publishingHouseIds = () => sessionStorage.getItem("publishingHouseIds");
/* End of Publishing House Ids */
window.deviceDetect = deviceDetect;
/* Portal Apis */
var auth = localStorage.getItem("Logged-in");
//console.log(auth);
if (auth === "true") auth = true;
else auth = false;
window.auth = auth;
let d = new Date();

let devicecode=99998;
if(isMobile){
   devicecode=99999 ;

}

export const URL_MENU = (Page, list, AppLanguage) => {
  let time ="";
  if(Page == "portalHome" || Page =="OTT_HomePwa"){
    time = new Date().getTime();

  }
  return `${BASE_URL()}${PATH_API_CAROUSEL}_info?version=26&group=${Page}&fields=title,generalInfo,images,relatedCast,publishingHouse,packages,contents,relatedMedia,user/currentdata&_=${auth}&x-myplex-tenant-id=${tenantId}&time=${time}`;
};

export const URL_CAROUSELCONTENT = (Name, startIndex, Count, modified_on, time=null, promotionId=null) =>{
  var url = `${META_URL}content/v2/carousel/${Name}?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata,stats&level=devicemin&startIndex=${startIndex}&count=${Count}&x-myplex-tenant-id=${tenantId}&modified_on=${modified_on}`;
  if(time){
    url += `&time=${time}`;
  }
  if(promotionId){
    url += `&promotionId=${promotionId}`;
  }
  return url;
}
  
  export const URL_CAROUSELCONTENTCONTENTID =  (Name, startIndex, Count, modified_on,ID)=>
  `${META_URL}content/v2/carousel/${Name}?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata&level=devicemin&startIndex=${startIndex}&count=${Count}&x-myplex-tenant-id=${tenantId}&contentId=${ID}&modified_on=${modified_on}`;

  export const URL_ContinueWatchingCAROUSELCONTENT = (
  Name,
  startIndex,
  Count
) => {
  var time = "";
  if (
    Name === "continue_watching_portal_mobile" ||
    Name === "IMT_portal_continue_watching" ||
    Name === "continueWatching_1Pwa" ||
    Name === "portal_continue_watching" ||
    Name.match(/MyReco/g) !== null
  ) {
    time = new Date().getTime();
  }
  // return `${BASE_URL()}content/v2/carousel/${Name}?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata,reviews/user&level=devicemin&startIndex=1&count=24&timestamp=${time}&x-myplex-tenant-id=${tenantId}`;
  // return `${BASE_URL()}content/v2/carousel/${Name}?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata,reviews/user&level=devicemin&startIndex=1&count=24&x-myplex-tenant-id=${tenantId}&modified_on=${time}`;
  return `${BASE_URL()}content/v2/carousel/${Name}?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata&level=devicemin&startIndex=${startIndex}&count=24&x-myplex-tenant-id=${tenantId}&modified_on=&time=${time}`;
};
// export const URL_SEARCHCONTENT = (query, Count) =>
//   `${BASE_URL()}content/v7/search/?fields=generalInfo,images,relatedCast,publishingHouse,contents,tags,relatedMedia,reviews/user,globalServiceId&&level=&query=${query}&startIndex=1&count=${Count}&orderBy=releaseDate&orderMode=1&publishingHouseId=${publishingHouseIds()}&type=movie%2Cvodchannel%2Ctvshow%2Ctvseries%2Clive&x-myplex-tenant-id=${tenantId}`;
  export const URL_SEARCHCONTENT = (query, Count,searchFields,type) =>
  `${BASE_URL()}content/v7/search/?query=${query}&type=${type}&fields=generalInfo%2Cimages%2CrelatedCast%2CpublishingHouse%2Ccontents%2CrelatedMedia%2Creviews%2Fuser%2CglobalServiceId&count=100&searchFields=${searchFields}&startIndex=1&x-myplex-tenant-id=${tenantId}`;
export const URL_FILTERTYPES = (type) =>
  `${BASE_URL()}custom/vfplay/v10/viewAllInside?type=${type}&x-myplex-tenant-id=${tenantId}`;
export const URL_LANGUAGES = (type) =>
  `${BASE_URL()}custom/vfplay/v10/languages?type=${type}&x-myplex-tenant-id=${tenantId}`;
// export const URL_FILTERDATA = (startIndex, type, language, genre, count) =>
//   `${BASE_URL()}content/v5/contentList/?fields=generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia,reviews/user,globalServiceId&orderBy=releasedate&startIndex=${startIndex}&count=${count}&type=${type}&language=${language}&genre=${genre}&publishingHouseId=${publishingHouseIds()}&x-myplex-tenant-id=${tenantId}`;
 export const URL_FILTERDATA = (startIndex, type, language, genre, count,tags) =>
   `${BASE_URL()}content/v5/contentList/?fields=generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia,globalServiceId&orderBy=releasedate&startIndex=${startIndex}&count=${count}&tags=${tags}&type=${type}&language=${language}&genre=${genre}&x-myplex-tenant-id=${tenantId}`;

export const URL_TVEPSIODES = (Id, startIndex, count,orderby) =>
  `${BASE_URL()}content/v3/vods/${Id}?fields=contents,videos,images,generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia,globalServiceId&startIndex=${startIndex}&count=${count}&orderMode=${orderby}&x-myplex-tenant-id=${tenantId}`;
  export const URL_TVEPSIODESSIMILAR = (Id, from, to,count) =>
  `${BASE_URL()}content/v3/vods/${Id}?fields=contents,videos,images,generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia,globalServiceId&serialNoFrom=${from}&serialNoTo=${to}&count=${count}&orderMode=1&x-myplex-tenant-id=${tenantId}`;
export const URL_CONTENTDATA = (Id) =>
  `${BASE_URL()}content/v3/contentDetail/${Id}/?level=devicemin&fields=thumbnailSeekPreview,subtitles,tags,title,videos,relatedCast,packages,generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia,skipConfig,user/currentdata&x-myplex-tenant-id=${tenantId}`;
export const URL_CONTENTVIDEO = (Id) =>
  `${BASE_URL()}content/v3/contentDetail/${Id}/?fields=thumbnailSeekPreviewvideos,videoInfo,relatedCast,packages,images,contents,subtitles,generalInfo,user/currentdata&fingerprint=${fingerprint()}&name=Chrome&type=webclient&modelNo=70.0.3538.67&os=linux&osVersion=4.15.0-39-generic&x-myplex-tenant-id=${tenantId}`;
export const URL_channelEPG = (Id) =>
  `${BASE_URL()}epg/v2/channelEPG/${Id}?date=${d.getFullYear()}-${(
    "0" +
    (d.getMonth() + 1)
  ).slice(
    -2
  )}-${d.getDate()}&level=epgstatic&imageProfile=mdpi&count=99&startIndex=1&orderBy=siblingOrder&orderMode=1&x-myplex-tenant-id=${tenantId}`;
export const URL_epgList = (genre, language) =>
  `${BASE_URL()}epg/v2/epgList?startDate=${d.getFullYear()}-${
    d.getMonth() + 1
  }-${d.getDate()}T${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}Z&level=epgstatic&imageProfile=mdpi&count=100&startIndex=1&orderBy=siblingOrder,location&genre=${genre}&language=${language}&x-myplex-tenant-id=${tenantId}`;
export const URL_LICENCE = (Id) =>
  `${BASE_URL()}licenseproxy/v2/modularLicense?content_id=${Id}&licenseType=streaming&variantType=null&fingerprint=${fingerprint()}`;
export const URL_LICENCEDRM = (Id) =>
  `${BASE_URL()}licenseproxy/v2/playreadyLicense?content_id=${Id}&licenseType=streaming&variantType=null&fingerprint=${fingerprint()}`;
export const URL_SIGNIN = () =>
  `${BASE_URL()}user/v3/mobile/signIn?x-myplex-tenant-id=${tenantId}`;
export const URL_YOUBROADBAND = () =>
  `${BASE_URL()}custom/vfplay/v20/partnerSignin`;
export const URL_SIMILARCONTENT = (Id) =>
  `${BASE_URL()}content/v2/similar/${Id}/?level=devicemax&count=10&x-myplex-tenant-id=${tenantId}`;
export const URL_RECOMMENDEDCONTENT = () =>
  `${BASE_URL()}content/v2/carousel/Recommendedforyou_pwa?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata&level=devicemin&startIndex=1&count=1000&x-myplex-tenant-id=${tenantId}`;
export const URL_LOGOUT = () => `${BASE_URL()}user/v2/signOut/`;
export const URL_WATCHLIST = (Id) =>
  `${BASE_URL()}user/v2/content/${Id}/favorite?&x-myplex-tenant-id=${tenantId}`;
export const URL_PLAYLIST = (Id) =>
  `${BASE_URL()}user/v2/content/${Id}/watchlist?&x-myplex-tenant-id=${tenantId}`;
export const URL_WATCHLISTDATA = (count) =>
  `${BASE_URL()}user/v2/contentList/favorites/?level=devicemin&count=${count}&publishingHouseId=${publishingHouseIds()}&x-myplex-tenant-id=${tenantId}`;
export const URL_PLAYLISTDATA = (count) =>
  `${BASE_URL()}user/v2/contentList/watchlist/?level=devicemin&count=${count}&publishingHouseId=${publishingHouseIds()}&x-myplex-tenant-id=${tenantId}`;
export const URL_WATCHHISTORYDATA = (count) =>
  `${BASE_URL()}content/v2/carousel/continueWatching?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata&level=devicemin&startIndex=1&count=${count}&x-myplex-tenant-id=${tenantId}`;
// export const URL_WATCHHISTORYDATA = (count) =>
//   `${BASE_URL()}user/v2/contentList/continueWatching?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia,packages,user/currentdata,reviews/user&level=devicemin&startIndex=1&count=${count}&x-myplex-tenant-id=${tenantId}`;
export const URL_MOVIE_STATUS = (Id) =>
  `${BASE_URL()}user/v2/events/player/${Id}/updateStatus`;
export const URL_UPDATE_MOU = (
  contentId,
  elapsedTime,
  timestamp,
  mediaSessionToken,tabname,cTitle,source,contentPosition,carouselPosition,os,contentType,trackingId
) =>
`${BASE_URL()}user/v2/events/mou/update/?contentId=${contentId}&elapsedTime=${elapsedTime}&timeStamp=${timestamp}&consumtionType=stream&x-myplex-tenant-id=${tenantId}&mediaSessionToken=${mediaSessionToken}&sourceTab=${tabname}&sourceDetails=${cTitle}&source=${source}&device_id=${devicecode}&contentPosition=${contentPosition}&carouselPosition=${carouselPosition}&os=${os}&contentType=${contentType}&trackingId=${trackingId}`;
  export const URL_UPDATE_PREVIEWMOU = (contentId, elapsedTime, timestamp) =>
  `${BASE_URL()}user/v2/events/mou/update/?contentId=${contentId}&elapsedTime=${elapsedTime}&timeStamp=${timestamp}&consumtionType=stream&contentType=preview&x-myplex-tenant-id=${tenantId}&device_id=${devicecode}`;
export const URL_YBSBUSCRIPTION = (contentId, mode, tid) =>
  `${BASE_URL()}/user/v6/subscription/offers/?source=contentDetail&contentId=${contentId}&version=2&x-myplex-tenant-id=${tenantId}&mode=${mode}&tid=${tid}&redirectUrl=${
    window.location
  };`;

export const URL_SEARCH_TYPE = (page) =>
  `${BASE_URL()}content/v2/carousel/_info?version=26&group=${page}&contentLanguage=&appLanguage=english&x-myplex-tenant-id=${tenantId}`;

export const URL_SEARCH_TYPE_DETAIL = (searchType) =>
  `${BASE_URL()}content/v2/carousel/${searchType}?&fields=title,generalInfo,images,relatedCast,publishingHouse,contents,relatedMedia&level=devicemin&startIndex=1&count=10&serviceName=VODAFONEPLAY&x-myplex-tenant-id=${tenantId}`;

export const URL_COUNTRIES_LIST = () =>
  `${BASE_URL()}content/v2/properties/country/list`;

export const URL_SIGNUP = () => `${BASE_URL()}user/v2/signUp`;

export const URL_NEW_SIGNIN_EMAIL = () => `${BASE_URL()}user/v2/signIn`;
export const URL_NEW_SIGNIN_MOBILE = () => `${BASE_URL()}user/v3/mobile/signIn`;

export const URL_ACTORPROFILE_CAROUSELS = (actorname, tags, type) =>
  `${BASE_URL()}content/v2/contentList?type=${type}&fields=generalInfo,images,tags&startIndex=1&count=100&orderBy=releaseDate&orderMode=0&person=${actorname}&x-myplex-tenant-id=${tenantId}&tags=${tags}`;

export const URL_MYPACKAGES = () =>
  `${BASE_URL()}user/v2/myPackages?x-myplex-tenant-id=${tenantId}`;

export const URL_CountryList = () =>
  `${BASE_URL()}content/v2/properties/country/list?x-myplex-tenant-id=${tenantId}`;

export const URL_STATELIST = (code) =>
  `${BASE_URL()}content/v2/properties/state/list/?&code=${code}`;

export const URL_Google_Login = () =>
  `${BASE_URL()}user/v2/social/login/Google/`;
export const URL_FACEBOOK_Login = () => `${BASE_URL()}user/v2/social/login/FB/`;

export const URL_PhonepeLogin = () =>
  `${BASE_URL()}user/v2/social/login/phonePe/`;

export const URL_FORGOT_PASSWORD = () => `${BASE_URL()}user/v2/forgotPassword`;

export const URL_DEVICE_ACTIVATE = () =>
  `${BASE_URL()}user/v4/device/confirm/?x-myplex-tenant-id=${tenantId}`;

export const URL_WORLDS_CAROUSELS = () =>
  `${BASE_URL()}content/v2/carousel/OOT_WORLDSPwa?&fields=title,generalInfo,tags,previews,images,relatedCast,publishingHouse,contents,relatedMedia&level=devicemin&startIndex=1&count=10&x-myplex-tenant-id=${tenantId}`;

export const URL_LOGIN_STATUS = () =>
  `${BASE_URL()}user/v2/profile?x-myplex-tenant-id=${tenantId}`;

export const URL_SSO_REVOKE = () =>
  `https://id.manoramaonline.com/token/revoke/`;

export const URL_ANALYTICS_EVENTS = () =>
  `${BASE_URL()}/custom/vfplay/v1/analytics/events`;

export const URL_SSO_TEST = () => `${BASE_URL()}user/v2/social/login/Mmtv`;

export const URL_SSO_APPINFO = () => `${BASE_URL()}user/v2/social/appInfo/Mmtv?x-myplex-tenant-id=${tenantId}`;
export const URL_HEARTBEAT = (contentId,elapsedTime,timeStamp) => `${BASE_URL()}user/v2/events/heartbeat/update/?contentId=${contentId}&elapsedTime=${elapsedTime}&timeStamp=${timeStamp}&consumtionType=stream&x-myplex-tenant-id=${tenantId}&device_id=${devicecode}`;
export const DEMOGRAPHICDATA = () => `${BASE_URL()}user/v2/demographicData/`;
export const PROMOBANNER = () => `${BASE_URL()}user/v2/bannerClicked/`;
axios.defaults.withCredentials = true;
let commanParams = localStorage.getItem("commonParams");
if (commanParams) {
  for (let i = 0; i < commanParams.length; i++) {
    if (commanParams[i].type === "header") {
      let headerKey = commanParams[i].key;
      axios.defaults.headers.common[headerKey] = commanParams[i].value;
    } else {
      axios.defaults.params = {
        [commanParams[i].key]: commanParams[i].value,
      };
    }
  }
}

const myplexAPI = {
  pullPageData: (Page, list, AppLanguage) =>
    axios({
      method: "GET",
      headers: {
        "X-myplex-platform": "browser",
        // "contentLanguage": list,
        // "app-language": AppLanguage
      },
      url: URL_MENU(Page, list, AppLanguage),
      cache: false,
    }),
  getCountryList: () =>
    axios({
      method: "GET",
      headers: {
        "X-myplex-platform": "browser",
        // "contentLanguage": list,
        // "app-language": AppLanguage
      },
      url: URL_CountryList(),
      cache: false,
    }),
  getStateList: (code) =>
    axios({
      method: "GET",
      headers: {
        "X-myplex-platform": "browser",
        // "contentLanguage": list,
        // "app-language": AppLanguage
      },
      url: URL_STATELIST(code),
      cache: false,
    }),
  signUpSubmit: (
    username,
    email,
    mobilenumber,
    country,
    gender,
    age,
    password1,
    password2
  ) =>
    axios({
      method: "POST",
      url: URL_SIGNUP(),
      // eslint-disable-next-line
      data: `payload=${encryption(
        "first=" +
          username +
          "&last=" +
          username +
          "&email=" +
          email +
          "&mobile=" +
          mobilenumber +
          "&gender=" +
          gender +
          "&password=" +
          password1 +
          "&password2=" +
          password2,
        "Ihj8D0btXvv4PBjy"
      ).replace(/\+/g, "%2b")}`,
      // data: `first=${username}&last=${username}&email=${email}&mobile=${mobilenumber}&gender=${gender}&password=${password1}&password2=${password2}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "x-myplex-platform": "browser",
      },
    }),

  signInSubmit: (userId, password) =>
    axios({
      method: "POST",
      url: URL_NEW_SIGNIN_EMAIL(),

      data: `payload=${encryption(
        "userid=" + userId + "&password=" + password,
        "Ihj8D0btXvv4PBjy"
      ).replace(/\+/g, "%2b")}`,
      headers: {
        "x-myplex-platform": "browser",
        "x-myplex-tenant-id": tenantId,
      },

      // data: `mobile=${mobile}&serviceName=${serviceName}&ucv=2`,
      // headers: {
      //   "ucv": 2
      // }
    }),
  // if (Page === "portalMobileNavMenu") {
  //   return axios.get("/static.json");
  // } else {
  // return axios.get(URL_MENU(Page,list), { cache: false });
  // }
  phonePeLogin: (token) =>
    axios({
      method: "POST",
      url: URL_PhonepeLogin(),

      data: `grantToken=${token}`,
      headers: {
        "x-myplex-platform": "browser",
        "x-myplex-tenant-id": tenantId,
      },

    
    }),
  authCodeSubmit: (authcode) =>
    axios({
      method: "POST",
      url: URL_DEVICE_ACTIVATE(),

      data: `auth_code=${authcode}`,
      // headers: {
      //   'x-myplex-platform': 'browser',
      //   "x-myplex-tenant-id": tenantId,
      // }
    }),

  signInMobile: (mobile) =>
    axios({
      method: "POST",
      url: URL_NEW_SIGNIN_MOBILE(),

      data: `mobile=${mobile}`,

      headers: {
        "x-myplex-platform": "browser",
        "x-myplex-tenant-id": tenantId,
      },
    }),

  updateProfileDetails: (country, state, city, address, pincode) =>

    axios({
      method: "POST",
      url: URL_LOGIN_STATUS(),
      data: `location=${country}&state=${state}&city=${city}&address=${address}&pincode=${pincode}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "X-myplex-platform": "browser",
      },
    }),

    updateProfileDetailsnew: (fname, lname, gender,dob, country, state,city) =>

    axios({
      method: "POST",
      url: URL_LOGIN_STATUS(),
      data: `first=${fname}&last=${lname}&gender=${gender}&dob=${dob}&location=${country}&state=${state}&city=${city}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "X-myplex-platform": "browser",
      },
    }),
  // updateProfileDetails: (country, state, city, address, pincode) =>

  
  //   axios({
  //     method: "POST",
  //     url: URL_LOGIN_STATUS(),
  //     data: `first=${FisrtName}&last=${LastName}&gender=${gender}&dob=${dob}&location=${country}&state=${state}&address=${address}&city=${city}&pincode=${pincode}`,
  //     headers: {
  //       "x-myplex-tenant-id": tenantId,
  //       "X-myplex-platform": "browser",
  //     },
  //   }),
  
  getWorldsCarouselData: () => axios.get(URL_WORLDS_CAROUSELS()),

  /*Content Language api call  */
  getContentLanguages: () => {
    return axios.get("/languageJson/contentLanguages.json");
  },
  getAppLanguages: () => {
    return axios.get("/languageJson/appLanguagesv2.json");
  },
  getDetailPageAppLangStings: () => {
    return axios.get("/languageJson/vernacular_strings.json");
  },
  getCarouselData: (Name, startIndex, Count, list, time = null, promotionId=null) =>
    axios({
      method: "GET",

      headers: {
        "X-myplex-platform": "browser",
        // "contentLanguage":list
      },
      url: URL_CAROUSELCONTENT(Name, startIndex, Count, list, time, promotionId),
    }),
    getCarouselDataWithContentId: (Name, startIndex, Count,modified_on, ID) =>
    axios({
      method: "GET",

      headers: {
        "X-myplex-platform": "browser",
        // "contentLanguage":list
      },
      url: URL_CAROUSELCONTENTCONTENTID(Name, startIndex, Count, modified_on,ID),
    }),
  getContinueWatchingData: (Name, startIndex, Count) =>
    axios.get(URL_ContinueWatchingCAROUSELCONTENT(Name, startIndex, Count)),
  getSearchResults: (query, count,searchFields,type) =>
    axios.get(URL_SEARCHCONTENT(query, count,searchFields,type)),
  getFilterTypes: (type) => axios.get(URL_FILTERTYPES(type)),
  getFilterValues: (url) =>
    axios.get(`${BASE_URL()}${url}&x-myplex-tenant-id=${tenantId}`),
  getFilterhResults: (startIndex, type, language, genre, count,tags) =>
    axios.get(URL_FILTERDATA(startIndex, type, language, genre, count,tags)),
  getTvshowEpisodes: (Id, startIndex, count,orderBy) =>
    axios.get(URL_TVEPSIODES(Id, startIndex, count,orderBy)),
    getTvshowEpisodessimilar: (Id, from,to, count,) =>
    axios.get(URL_TVEPSIODESSIMILAR(Id, from,to, count)),
  getContentData: (Id) => axios.get(URL_CONTENTDATA(Id)),
  getchannelEPG: (Id) => axios.get(URL_channelEPG(Id)),
  getEPGList: (genere, language) => axios.get(URL_epgList(genere, language)),
  getContentVideo: (Id) => axios.get(URL_CONTENTVIDEO(Id)),
  getMediaAPi: (URL) =>
    axios({
      method: "GET",
      headers: {
        "X-myplex-platform": "browser",
        "x-myplex-tenant-id": tenantId,
      },
      // url: URL_MEDIA(Id)
      url: BASE_URL() + URL,
    }),

  getSearchTypes: (page) => axios.get(URL_SEARCH_TYPE(page)),
  getSearchTypesDetail: (type) => axios.get(URL_SEARCH_TYPE_DETAIL(type)),
  // getTrendingTvShowsApi :() => axios.get(URL_TRENDING_TV_SHOWS()),
  getSimilarContent: (Id) => axios.get(URL_SIMILARCONTENT(Id)),
  getRecommendedContent: () => axios.get(URL_RECOMMENDEDCONTENT()),
  getLanguages: (Type) => axios.get(URL_LANGUAGES(Type)),
  getwatchlist: (Id) => axios.get(URL_WATCHLIST(Id)),
  getplaylist: (Id) => axios.get(URL_PLAYLIST(Id)),
  watchlistData: (query, count) => axios.get(URL_WATCHLISTDATA(query, count)),
  playlistData: (query, count) => axios.get(URL_PLAYLISTDATA(query, count)),
  watchHistoryData: (count) => axios.get(URL_WATCHHISTORYDATA(count)),
  updatePlayerMou: (contentId, elapsedTime, timestamp, mediaSessionToken,tabname="",cTitle="",source="", contentPosition,carouselPosition,os="",contentType,trackingId="" ) =>
    axios.get(
      URL_UPDATE_MOU(contentId, elapsedTime, timestamp, mediaSessionToken,tabname,cTitle,source,contentPosition,carouselPosition,os,contentType,trackingId)
    ),
    updatePreviewMou: (contentId, elapsedTime, timestamp) =>
    axios.get(URL_UPDATE_PREVIEWMOU(contentId, elapsedTime, timestamp)),
  ybSubscription: (contentId, mode, tid) =>
    axios.get(URL_YBSBUSCRIPTION(contentId, mode, tid)),
  userLoginStatus: () => axios.get(URL_LOGIN_STATUS()),
  getCountriesList: () => axios.get(URL_COUNTRIES_LIST()),
  getMyPackages: () => axios.get(URL_MYPACKAGES()),

  getActorProfileCarousels: (actorname, tags, type) =>
    axios.get(URL_ACTORPROFILE_CAROUSELS(actorname, tags, type)),
  contentLanguageProfileUpdate: (languages) =>
    axios({
      method: "POST",
      url: URL_LOGIN_STATUS(),
      data: `language=${languages}`,
    }),
  // heLogin: () =>
  //   axios({
  //     method: "POST",
  //     // url: "http://api.vodafoneplay.in/user/v3/mobile/signIn",
  //     url: `${he_url}user/v3/mobile/signIn`,
  //   }),
  getOTP: (mobile) =>
    axios({
      method: "POST",
      url: URL_SIGNIN(),
      data: `mobile=${mobile}`,
      // headers: {
      //   "ucv": 2
      // }
      headers: {
        "x-myplex-tenant-id": tenantId,
      },
    }),
  sendOTP: (mobile, otp) =>
    axios({
      method: "POST",
      url: URL_SIGNIN(),
      data: `mobile=${mobile}&otp=${otp}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "X-myplex-platform": "browser",
      },
    }),
  logOut: () =>
    axios({
      method: "post",
      url: URL_LOGOUT(),
      headers: {
        "x-myplex-tenant-id": tenantId,
        "X-myplex-platform": "browser",
      },
    }),
  ssoLogout: () =>
    axios({
      method: "POST",
      url: URL_SSO_REVOKE(),
      data: {
        client_secret: "",
        token: localStorage.getItem("token"),
        client_id: "max-apalya-dev",
      },
      // headers: {
      //   "x-myplex-tenant-id": tenantId,
      //   "X-myplex-platform": "browser"
      // }
    }),
  YoubroadbandLogin: (mobile, password) =>
    axios({
      method: "POST",
      url: URL_YOUBROADBAND(),
      data: `mobile=${mobile}&password=${password}&partner ='youbroadband'`,
    }),
  watchliststate: (Id, State, Type) =>
    axios({
      method: "post",
      url: URL_WATCHLIST(Id),
      data: `favorite=${State}&contentType=${Type}`,
    }),
  playliststate: (Id, State, Type) =>
    axios({
      method: "post",
      url: URL_PLAYLIST(Id),
      data: `favorite=${State}&contentType=${Type}`,
    }),
  playerStatus: (id, action, stream, elapsedTime, mediaSessionToken) =>
    axios({
      method: "post",
      url: URL_MOVIE_STATUS(id),
      data: `action=${action}&streamName=${stream}&elapsedTime=${elapsedTime}&mediaSessionToken=${mediaSessionToken}`,
    }),
  analyticsEvents: (category, action, label, value) =>
    axios({
      method: "post",
      url: URL_ANALYTICS_EVENTS(),
      data: `category=${category}&action=${action}&label=${label}&value=${value}`,
    }),
  properties: () =>
    axios({
      method: "get",
      url:
        `${BASE_URL()}content/v2/properties/all?x-myplex-tenant-id=` +
        tenantId,
      headers: {
        "x-myplex-tenant-id": tenantId,
      },
    }),
  publishingHouseIds: () => {
    if (sessionStorage.getItem("publishingHouseIds") == null || sessionStorage.getItem("searchTabs") == null) {
      axios
        .get(`${BASE_URL()}content/v2/properties/all?x-myplex-tenant-id=${tenantId}`)
        .then((response) => {

          sessionStorage.setItem('properties',JSON.stringify(response.data.properties));
          let publishingHouseId;
          
          sessionStorage.setItem(
            "searchTabs",
            response.data.properties.searchConfig.replace(/\\/g, "")
          );

          publishingHouseId = response.data.properties.wappublishingHouseId;

          localStorage.setItem(
            "tags",
            response.data.properties.profileListV2Android.replace(/\\/g, "")
          );

          // if (app === "idea") {
          //   publishingHouseId =
          //     response.data.properties.imtWapPublishingHouseId;
          // }
          sessionStorage.setItem(
            "desktopPublishingHouseIds",
            response.data.properties.desktopPublishingHouseIds
          );
          sessionStorage.setItem("publishingHouseIds", publishingHouseId);
          localStorage.setItem(
            "proporties",
            response.data.properties.partnerDetailsV23.replace(/\\/g, "")
          );

          if (app === "vodafone") {
            if (response.data.properties.pwaScrollPopupJson !== undefined) {
              sessionStorage.setItem(
                "pwaScrollPopupJson",
                response.data.properties.pwaScrollPopupJson.replace(/\\/g, "")
              );
            }
          }
          if (app === "idea") {
            if (response.data.properties.pwaScrollPopupJsonIdea !== undefined) {
              sessionStorage.setItem(
                "pwaScrollPopupJson",
                response.data.properties.pwaScrollPopupJsonIdea.replace(
                  /\\/g,
                  ""
                )
              );
            }
          }
          // window.proporties = response.data.properties.partnerDetailsV23;
          return sessionStorage.getItem("publishingHouseIds");
        })
        .catch((error) => {
          /* defalult publishingHouseIds */
          return "1,5,10,43,51,52,53,54,55,56,57,59,61,63,65,73,76";
        });
    } else {
      return sessionStorage.getItem("publishingHouseIds");
    }
  },
  storeIndexedDb: (responseURL, results) => {
    if (config.indexedDB) {
      const data = {
        name: responseURL,
        data: results,
        created_at: Date.now(),
      };
      db.table("carousels").put(data);
    }
  },

  ssoLogin: (mmtvid, idtoken, accesstoken) =>
    axios({
      method: "post",
      url: URL_SSO_TEST(),
      data: `idToken=${idtoken}&mmtvId=${mmtvid}&authToken=${accesstoken}&tokenExpiry=`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "x-myplex-platform": "browser",
        // "x-samesite":"true"
       
      },
    }),

  getSsoAppInfo: () =>
    axios({
      method: "GET",
      headers: {
        "X-myplex-platform": "browser",
        "x-myplex-tenant-id": tenantId,
        // "contentLanguage": list,
        // "app-language": AppLanguage
      },
      url: URL_SSO_APPINFO(),
      cache: false,
    }),
  googleLogin: (idToken, googleId, authToken, tokenExpiry, pretty) =>
    axios({
      method: "post",
      url: URL_Google_Login(),
      data: `idToken=${idToken}&googleId=${googleId}&authToken=${authToken}&tokenExpiry=${tokenExpiry}&pretty=${pretty}`,
    }),
  facebookLogin: (facebookId, authToken, tokenExpiry) =>
    axios({
      method: "post",
      url: URL_FACEBOOK_Login(),
      data: `facebookId=${facebookId}&authToken=${authToken}&tokenExpiry=${tokenExpiry}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
      },
    }),
  forgotPassword: (email) =>
    axios({
      method: "post",
      url: URL_FORGOT_PASSWORD(),
      data: `email=${email}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
      },
    }),
    heartbeat: (contentId,elapsedTime,timeStamp) => axios.get(URL_HEARTBEAT(contentId,elapsedTime,timeStamp)),
    demographicData: (data) =>
    axios({
      method: "post",
      url: DEMOGRAPHICDATA(),
      data: `gender=${data.gender}&dob=${data.dod}`,
      headers: {
        "x-myplex-tenant-id": tenantId,
        "x-myplex-platform": "browser",
      },
    }),
    promobanner: (layouttype,layoutname) => 
      
      axios({
        method :"post",
        url:PROMOBANNER(),
        data:`layoutType=${layouttype}&layoutName=${layoutname}`,
       
        headers:{
          "x-myplex-tenant-id": tenantId,
          "x-myplex-platform": "browser",
          
        },
       
      }),
    CancelAutoRenewal: (url) =>
      axios({
        method: "get",
        url: `${BASE_URL()}${url}`,
        headers: {
          "x-myplex-tenant-id": tenantId,
        },
      }),
};
export default myplexAPI;
